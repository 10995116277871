import axios from 'axios'
import tokenExtractor from '../utils/TokenExtractor'
import Constantes from '../utils/Constantes'

// const baseUrl = '/api/clases'
// const baseUrl = 'https://api.garredev.online:3003/api/clases'
// const baseUrl = 'http://18.159.102.65:3003/api/clases'
const baseUrl = Constantes.getURL() + 'clases'


const getClases = async () =>{
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl, config)
    // console.log('RESPUESTA: ', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getClasesNames = async () => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl+'/nombres', config)
    // console.log('RESPUESTA: ', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getDescuentos = async () => {
  
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl+'/descuentos', config)
    // console.log('RESPUESTA: ', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

export default {
  getClases,
  getClasesNames,
  getDescuentos
}