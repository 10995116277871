import SpinLoad from "./SpinLoad"

// const CortinaCrearMensualidades = ({setExecCrearMensualidades, bloqueos, setCurrentOperation, setLastScreen, setScreen }) => {
  const CortinaCrearMensualidades = ({setCortina, mensajeCortina, ponerBotonCerrarCortina}) => {
  console.log('XXXXXXXXXX')
  return(
    <div 
      className="menucontainer active cortina-error" 
      id='menucontainer'
    >
      <div className="mensaje-error-cortina">
        {/* <p>Creando mensualidades de todos los alumnos. Por favor, espere...</p> */}
        <p>{mensajeCortina}</p>
        <br></br>
        
        
        {
          ponerBotonCerrarCortina
            ? <>
                <br></br>
                <button className="button-52" onClick={()=>setCortina(false)}>cerrar</button>
              </>
            : <SpinLoad />
        }
        

      </div>
    </div>
  )
}

export default CortinaCrearMensualidades