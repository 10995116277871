import { useEffect, useState } from 'react'
import studentService from '../services/students'
import GetFechas from '../utils/GetFechas'

const NotesListViewer = ({currentStudent, user}) => {
  
  const [ notas, setNotas ] = useState([])
  const [ nuevaNota, setNuevaNota ] = useState('')
  
  useEffect(()=>{
    studentService
      .getStudent(currentStudent)
      .then(result => {
        setNotas(result.notas)
      })
  },[])

  const handleNewNote = async () => {
    if(nuevaNota){
      const studentDTO = {
        "_id": currentStudent,
        "notas": notas.concat(user.name + ': ' + GetFechas.getFechaActual() + '_' + nuevaNota)
      }
      const studentReturned = await studentService.putStudent(studentDTO)
      console.log('El estudiante devuelto: ', studentReturned)
      setNotas(studentReturned.notas)
      setNuevaNota('')
    }
  }

  return(
    <>
      <p>Notas</p>

      {
        notas.length >0 &&
          <div className='lista-notas'>
            {
              notas.map(nota=>{
                return(
                  <div className='nota-bloque'>
                    <u className='nota-fila'>{nota.split('_')[0]}</u>
                    {/* <u className='nota-fila'>{nota.split('_')[1]}</u> */}
                    <p className='nota-fila'>{nota.split('_')[1]}</p>
                  </div>
                )
              })
            }
          </div>
      }

    
      <textarea 
        id="txtDescripcion" className="input-descripcion" rows="3" placeholder='Nueva nota ...'
        value={nuevaNota}
        onChange={(e)=>setNuevaNota(e.target.value)}
      >
      </textarea>  
        
      <br></br>
      <br></br>
      
      <button 
        className="button-52"
        onClick={handleNewNote}
      >
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        <span>Añadir nota</span>
      </button>
    </>
  )
}

export default NotesListViewer