const getMensualidadActual = () => {
  const fechaActual = new Date()
  // console.log('Fecha actual: ', fechaActual)
  const mes = (fechaActual.getMonth()+2).toString().padStart(2, '0')
  // console.log('mes: ', mes)
  const year = fechaActual.getFullYear()
  // console.log('Año: ', year)
  return year + '-' + mes
}

const getFechaActual = () => {
  const fechaActual = new Date()
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que sumamos 1.
  const año = fechaActual.getFullYear();

  const hora = fechaActual.getHours();
  const minutos = fechaActual.getMinutes();
  const segundos = fechaActual.getSeconds();

  const fechaFormateada = `${dia}-${mes}-${año} ${hora}:${minutos}:${segundos}`;
  
  return fechaFormateada
}

export default {
  getMensualidadActual, getFechaActual
}