import { useEffect, useState } from 'react'
import studentsService from '../services/students'
import operationService from '../services/operations'
import clasesCalculator from '../utils/ClasesCalculator'
import SpinLoad from './SpinLoad'
import CortinaError from './CortinaError'

const OperationCreationForm = ({currentStudent, setCurrentOperation, setLastScreen, setScreen}) => {
  
  const [ student, setStudent] = useState(null)
  const [ tipo, setTipo ] = useState('mensual')
  const [ mes, setMes ] = useState('')
  const [ precio, setPrecio ] = useState('')
  const [ description, setDescription ] = useState('')
  const [ pagado, setPagado ] = useState(false)
  const [ bloqueos, setBloqueos ] = useState([])
  // let currentDate = ''

  const getFecha = () => {
    const date = new Date()
    const anno = date.getFullYear()
    // const mes = (date.getMonth()+1).toString().padStart(2, '0')    //Mes actual
    const mes = (date.getMonth()+2).toString().padStart(2, '0')       //Mes siguiente
    const dia = date.getDate().toString().padStart(2, '0')
    // console.log('Fecha formateada: ', `${anno}-${mes}-${dia}`)
    // return `${anno}-${mes}-${dia}`
    return `${anno}-${mes}`
  }
  // currentDate = getFecha()

  useEffect(()=>{
    // setCurrentDate(getFecha())
    // console.log(`XXXXXXXXX ${currentDate.split('-')[0]}-${currentDate.split('-')[1]}`)
    // setMes(`${currentDate.split('-')[0]}-${currentDate.split('-')[1]}`)
    setMes(getFecha())
    studentsService
      .getStudent(currentStudent)
      .then((result)=>{
        setStudent(result)
        if(student)
          clasesCalculator.calcular(student)
      })
  }, [])

  const getDetalles = () => {
    let detallesFactura = []

    if(tipo==='mensual'){
      detallesFactura = detallesFactura.concat(tipo + ': ' + mes)
      // clases
      clasesCalculator.getClasesArray().map(elem=>detallesFactura = detallesFactura.concat(elem.nombre + ': ' + elem.mensualidadDeClase + '€'))
      
      // descuentos
      if(student){
        if(student.dtoActivity)
          detallesFactura = detallesFactura.concat('descuento por 2ª actividad: ' + clasesCalculator.getDescuentos().dtoActivity + '%')
        if(student.dtoFamily)
          detallesFactura = detallesFactura.concat('descuento por familiar: ' + clasesCalculator.getDescuentos().dtoFamily + '%')
        if(student.dto)
          detallesFactura = detallesFactura.concat('descuento: ' + student.dto + '%')
      }

      // total
      detallesFactura = detallesFactura.concat('Total: ' + clasesCalculator.getMensualidad() + '€')
    }else{
      detallesFactura = detallesFactura.concat(tipo)
      detallesFactura = detallesFactura.concat('Total: ' + precio + '€')
    }
    
    return detallesFactura
  }

  const handleSubmit = async () => {
    // comprobamos si estamos intentando hacer una operacion con bloqueo
    if(tipo==='mensual')
    {
      const currentDate = new Date(`${mes}-01`)
      console.log('En manejador de SUBMIT')
      console.log('operacion mensual')
      console.log('Operaciones de estudiante: ', student.operations)
      const opsBloqueantes = student.operations.filter(elem=>{
        return pagado && (elem.opType === 'mensual') && (!elem.paid) && (new Date(`${elem.name}-01`) < currentDate)
      })
      console.log('Array de operaciones bloqueantes: ', opsBloqueantes)
      // alert('Operación bloqueada por impago de operaciones anteriores: ', opsBloqueantes)
      if(opsBloqueantes.length > 0){
        setBloqueos(opsBloqueantes)
        return
      } 
    }

    if(tipo==='mensual')
      if(student.operations.map(elem=>elem.name).includes(mes)){
        console.log('Esta operacion mensual ya existe para este usuario')
        alert('Operacion de mensualidad ya existe.')
        return
      }

    const detalles = getDetalles()
   
    const operacionDTO = {
      name: tipo==='mensual' ? mes : (mes.split('-')[0] + '-' + tipo.toUpperCase()),
      opType: tipo==='mensual' ? 'mensual' : 'otros',
      blocking: tipo==='mensual',
      paid: pagado,
      detailed: detalles,
      price: tipo==='mensual' ? clasesCalculator.getMensualidad() : precio,
      description: description,
      studentId: student._id
    }
    
    try {
      const operationReturned = await operationService.postOperation(operacionDTO)
      setCurrentOperation(operationReturned._id)
      setLastScreen('operacionesCrear')
      setScreen('operacionFicha')
    } catch (error) {
      console.log(error)
      alert(error.response.data.error)
    }

  }

  return(
    <>
      {
        bloqueos.length > 0
          && <CortinaError 
              closeCortina={()=>setBloqueos([])} 
              bloqueos={bloqueos}
              setCurrentOperation = {setCurrentOperation} 
              setLastScreen = {setLastScreen}
              setScreen = {setScreen}
            />
      }
      {/* {console.log('ESTUDIANTE: ', student)} */}
      {/* {console.log('FECHA ACTUAL: ', currentDate)} */}
      {/* {console.log('FECHA MES: ', mes)} */}
      {/* {student && <h4>para {student.name}</h4>} */}
      <h4>para {student && student.name}</h4>
      <form onSubmit={(event) => {
        event.preventDefault()
        handleSubmit()
      }}>


        {/* Seleccionar el tipo de la operacion */}
        <div className="formulario-group">
          <div className="select-wrapper">
            <select 
              className="button-52-select"
              onChange={(e)=>{
                setTipo(e.target.value)
              }}
            >
              <option selected value="mensual">mensual</option>
              <option value="matrícula">matrícula</option>
              <option value="vestuario">vestuario</option>
              <option value="festival">festival</option>
              <option value="otros">otros</option>

            </select>

            {/* INPUT CALENDARIO */}
            {
              tipo === 'mensual'
                ? <input onChange={(e)=>{
                      setMes(e.target.value)
                    }}
                    type="month" id="start" name="trip-start"
                    value={mes} min="1940-01" max="2025-12"
                    className='calendario-mes-operacion'
                  />
                : <span className='precio-from-crear-op'>
                    precio
                    <input
                      onChange={e=>setPrecio(e.target.value)}
                      className="input-precio-op"
                      value={precio} 
                    />
                  </span>  
            }
          </div>
          {
            tipo === 'mensual'
              &&  <div className="factura">
                    {
                      clasesCalculator.getClasesArray().map(elem=>{
                        return (
                          <div className='item'>
                            {/* {elem.nombre}: <span className='mensualidad-clase-crear-op'>{elem.mensualidadDeClase}€</span> */}
                            {elem.nombre}: <span className='mensualidad-clase-crear-op'>{elem.mensualidadOrigen}€</span>
                          </div>
                        )
                      })
                    }
                    <hr></hr>
                    <div className="subtotal">subtotal: <span class="subhighlight">{clasesCalculator.getMensualidadOrigen()}€</span></div>
                    {
                      student 
                        && student.dtoActivity 
                          &&  <div className='item'>dto 2ªact <span className='mensualidad-clase-crear-op'>-{clasesCalculator.getDescuentos().dtoActivity}%</span></div>                    
                    }
                    {
                      student 
                        && student.dtoFamily 
                          && <div className='item'>dto familiar <span className='mensualidad-clase-crear-op'>-{clasesCalculator.getDescuentos().dtoFamily}%</span></div>
                    }
                    {
                      student 
                        && student.dto > 0
                          && <div className='item'>dto <span className='mensualidad-clase-crear-op'>-{student.dto}%</span></div>
                    }
                    <hr></hr>
                    <div className="total">Total: <span class="highlight">{clasesCalculator.getMensualidad()}€</span></div>
                  </div>
          }
        </div>

        

        {/* TEXTAREA PARA DESCRIPCION */}
        <div className="formulario-group">
          <div className='descripcion'>
            {/* <label for="txtDescripcion">Descripción:</label> */}
            <textarea 
              id="txtDescripcion" className="input-descripcion" rows="4" placeholder='descripción...'
              value={description}
              onChange={(e)=>setDescription(e.target.value)}
            >
            </textarea>  
          </div>
        </div>
        
        {/* PAGADO??    CREAR*/}
        <div className="formulario-group cuadro-opciones-filtro-op">
          {/* <div className='cuadro-opciones-filtro-op'> */}
            <div className='cuadro-opciones-crear-op-group boton-pagado'>
              <input
                onClick={()=>{
                  setPagado(valor=>!valor)
                }} 
                type="checkbox" id="switch-pagado"/>
              <label for="switch-pagado">Toggle</label>
              {
                pagado
                  ? <span>pagado</span>
                  : <span>no pagado</span>
              } 
            </div>
            <span>
              <button
                type="submit" 
                className="button-52-formulario-crear-op"
              >
                crear
              </button>
            </span>
          {/* </div> */}
        </div>

        {/* CREAR */}
        {/* <button
          type="submit" 
          className="button-52-formulario"
        >
          crear
        </button> */}
      </form>
    </>
  )
}

export default OperationCreationForm