import { useEffect, useState } from "react"
import OperationService from '../services/operations'
import StudentService from '../services/students'


const OperationCard = ({currentOperation, setCurrentStudent, setScreen, setLastScreen}) => {
  const [operation, setOperation] = useState(null)
  
  useEffect(()=>{
    OperationService
    .getOperation(currentOperation)
    .then((result) => {
      console.log('OPERACION OBTENIDA EN OP TARJETA: ', result)
      setOperation(result)
    })
    .catch((error) => {
      console.log('ERROR desde OPERATION CARD: ', error)
    })
  },[])

  // Funcion para poner el Nombre del Mes en la renderizacion
  const operacionNombre = () => {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    // console.log(`${operation.name.split('-')[0]}-${meses[Number(operation.name.split('-')[1]-1)]}`)
    if(operation.name.split('-')[1].length > 2)
      return operation.name
    return `${operation.name.split('-')[0]}-${meses[Number(operation.name.split('-')[1]-1)]}`
  }
  
  if(operation){
    return(
      
      <>
        <div className="ac-card-operation">
          {/* <div className="ac-card-image"></div> */}
          <div className="ac-card-info">
            
            <p className="nombre-op">
              <strong>{operacionNombre()}</strong>
            </p>

            <p onClick={()=>{
                setScreen('estudianteFicha')
                setLastScreen('operacionFicha')
                console.log('SABER EL ESTUDIANTE DE LA op: ', operation)
                setCurrentStudent(operation.student._id)
              }} 
              className={`nombre-ficha ${operation.paid? 'paid' : 'not-paid'}`}>
               {/* <strong id="name">{operation.student.name}</strong> */}
               {operation.student.name} <i class="fa fa-id-card-o" aria-hidden="true"></i>
               
            </p>
          
            <hr/>
            {
              operation.detailed.map(detail=>{
                return(
                  <p className='detalle-linea-ficha'>{detail}</p>
                )
              })
            }
          </div>
          {/* <img className="ac-icon" src="https://imdac.github.io/images/codepen/AC_ICON_1C_BLK_70x58.png" /> */}
          <img id='logoImage' src='https://www.elitedanza.com/images/logo-01-semi.png' />
          <div className="ac-card-footer">
            {/* <img className="ac-logo" src="https://imdac.github.io/images/codepen/AC_WORDMARK_1C_WHT.png" /> */}
            {/* <h6>elite danza</h6> */}
            <p className={`${operation.paid?'pagoRealizado-ficha':'pagoPendiente-ficha'}`}>
              Cantidad: {operation.price} €
              {
                operation.paid
                  ? <i className="fa fa-check-circle-o" aria-hidden="true"></i>
                  : <i className="fa fa-credit-card" aria-hidden="true"></i>
              }
            </p>
          </div>
        </div>
        {
          operation.description 
            &&  <div className="descripcion-mostrada">
                  <p>
                    {operation.description}
                  </p>
                </div>
        }
        
      </>  
    )
  }
}

export default OperationCard