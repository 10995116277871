import {useState} from 'react'
import operationsService from "../services/operations"


// const CortinaPagarBorrarOp = ({setExecCrearMensualidades, bloqueos, setCurrentOperation, setLastScreen, setScreen }) => {
  const CortinaPagarBorrarOp = ({setCortina, currentOp, user}) => {

  const [error, setError] = useState('')

  console.log(user)
  return(
    <div 
      className="menucontainer active cortina-error" 
      id='menucontainer'
    >
      <div className="mensaje-error-cortina">
        <p>{currentOp.name} de {currentOp.student.name}</p>
        <p>{currentOp.price}€</p>
        <br></br>
        {
          error
            && <p>{currentOp.student.name} tiene meses pendientes de pago.</p>
        }
        {
          // !currentOp.paid &&
            <button 
              onClick={()=>{
                // confirmamos operación de marcar NO PAGADO
                if(currentOp.paid && !window.confirm('marcar como NO PAGADA?')){
                  return
                }
                
                operationsService
                  .putPagarOperation({
                    '_id':currentOp._id,
                    'name':currentOp.name,
                    // 'paid':true,
                    'paid': !currentOp.paid,
                    'studentId':currentOp.student._id
                  })
                  .then(result=>{
                    console.log('devuelto: ', result)
                    setCortina(false)
                  })
                  .catch(error => {
                    console.log('ERROR: ', error)
                    setError('tiene meses atrasados de pago')
                  })
              }}
              className="button-52 button-pagar"
            >
              <b>{!currentOp.paid ? 'PAGAR' : 'NO PAGADA'}</b>
            </button>
        }
        
        <br></br>

        {
          (user.roles==='admin' || user.roles==='directora') &&
            <button 
              onClick={()=>{
                if(window.confirm('BORRAR la operación?')){
                  console.log('borrar')
                  console.log('StudentID: ', currentOp.student._id)
                  console.log('OperationID: ', currentOp._id)
                  // const opDTO = {
                  //   "_id": currentOp._id,
                  //   "studentId": currentOp.student._id
                  // }
                  operationsService
                    .removeOperation(currentOp._id)
                    .then(result=>{
                      console.log('OP Borrada: ', result)
                      setCortina(false)
                    })
                }
                // alert('Seguro que quieres borrar esta operación?')
              }}
              className="button-52 button-borrar"
              >
                <b><i class="fa fa-trash-o" aria-hidden="true"></i></b>
            </button>
        }

        <br></br>
        <hr></hr>
        <br></br>
        
        <button className="button-52" onClick={()=>setCortina(false)}>cerrar</button>
        
            
        
        

      </div>
    </div>
  )
}

export default CortinaPagarBorrarOp