import { useEffect, useState } from "react"
import StudentsListViewer from "./StudentsListViewer"
import clasesService from '../services/clases'

const StudentFilter = ({students, setScreen, setCurrentStudent}) => {
  
  const [ search, setSearch ] = useState('')
  const [ selectionClass, setSelectionClass ] = useState('')
  const [ morosos, setMorosos ] = useState(false)

  const [optionsVisibility, setOptionsVisibility] = useState(false)
  const [clasesNames, setClasesNames] = useState([])

  useEffect(()=>{
    clasesService
      .getClasesNames()
      .then(response=>setClasesNames(response))
      .catch(error=>alert(error))
  },[])


  const debeMensualidad = (elem) => {
    return elem.operations.some(op=>(op.opType==='mensual' && !op.paid))
  }

  const filtroListado = () => {
    let studentsFiltered = students
    if(search)
    studentsFiltered = studentsFiltered.filter(student => student.name.toLowerCase().includes(search.toLowerCase()))
    // studentsFiltered = studentsFiltered.filter(student => student.name.toLowerCase().includes(search.toLocaleLowerCase()))
    if(selectionClass){
      console.log('STUDENT FILTERED: ', studentsFiltered)
      console.log('SELECTION_CLASS: ', selectionClass)
      studentsFiltered = studentsFiltered.filter(elem =>elem.clases.find(clase=>clase.nombre === selectionClass))
    }
    if(morosos)
      studentsFiltered = studentsFiltered.filter(elem => debeMensualidad(elem))
    return studentsFiltered
  }

  // console.log('Valor de la lista de estudiantes FILTRADA: ', filtroListado())

  return (
    <>
      <input
        className="button-52"
        value={search}
        onChange={e => setSearch(e.target.value)}
        id={'campoFocus'}
      />
      <span><i className="fa fa-search" aria-hidden="true"></i></span>
      <span>
        <button
          onClick={()=>setOptionsVisibility(value=>!value)} 
          className='button-52-despliegue-filtros-ops'>
          <i className="fa fa-filter" aria-hidden="true"></i>
        </button>
      </span>
      {
        optionsVisibility
          ? 
            <>
              <hr></hr>

              <div className='cuadro-opciones-filtro-op'>
                <div className="select-wrapper">
                  <select 
                    className="button-52-select"
                    onChange={(e)=>setSelectionClass(e.target.value)}
                  >
                    <option selected value="">todas las clases</option>
                    {
                      clasesNames.map(clase=><option key={clase} value={clase}>{clase}</option>)
                    }
                  </select>
                </div>
              
                <div className='cuadro-opciones-filtro-op-group'>
                  <input
                    onClick={()=>{
                      setMorosos(valor=>!valor)
                    }} 
                    type="checkbox" id="switch" />
                  <label for="switch">Toggle</label>
                  <span>deben</span>
                </div>

              </div>

              <hr></hr>
            </>
          : ''
      }
      <StudentsListViewer studentsFiltered={filtroListado()} setScreen={setScreen} setCurrentStudent={setCurrentStudent}/>
    </>
  )
}

export default StudentFilter