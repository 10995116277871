import { useState, useEffect } from "react"
import studentsService from '../services/students'
import clasesService from '../services/clases'

const StudentEditForm = ({setScreen, setLastScreen, setCurrentStudent, currentStudent}) => {
  const [ studentId, setStudentId ] = useState('')
  const [ studentName, setStudentName ] = useState('')
  const [ tutorName, setTutorName ] = useState('')
  const [ telephone, setTelephone ] = useState('')
  const [ telephoneArray, setTelephoneArray ] = useState([])
  const [ birthDate, setBirthDate ] = useState('')
  const [ clasesSelecteds, setClasesSelecteds ] = useState([])
  const [ clasesNames, setClasesNames ] = useState([])
  const [ active, setActive ] = useState(true)
  // const [ corrientePago, setCorrientePago ] = useState(true)
  const [ dtoFamily, setDtoFamily ] = useState(false)
  const [ dtoActivity, setDtoActivity ] = useState(false)
  const [ dto, setDto ] = useState(0)
  const [ notas, setNotas ] = useState([])
  const [ visibilityChecks, setVisibilityChecks ] = useState(false)

  useEffect(()=>{
    setBirthDate(getFecha())
    clasesService
      .getClasesNames()
      .then(response=>setClasesNames(response))
      .catch(error=>alert(error))
  },[])

  useEffect(()=>{
    studentsService
      .getStudent(currentStudent)
      .then(result=>{
        console.log('estudiante obtenido: ', result)
        setStudentId(result._id)
        setStudentName(result.name)
        setTutorName(result.tutor)
        setTelephoneArray(result.telephones)
        setBirthDate(result.birthDate.split('T')[0])
        setClasesSelecteds(result.clases)
        setDtoFamily(result.dtoFamily)
        setDtoActivity(result.dtoActivity)
        setDto(result.dto)
        setActive(result.active)
        console.log('NOTAS: ', result.notas)
        setNotas(result.notas)
      })
  },[])

  const handleSubmit = async () => {
    const studentDTO = {
      '_id': studentId,
      'name': studentName,
      'tutor': tutorName,
      'telephones': telephoneArray,
      'birthDate': birthDate,
      'clases': clasesSelecteds,
      // 'state': corrientePago ? 'corriente' : 'moroso',
      'active': active,
      'dtoFamily': dtoFamily,
      'dtoActivity': dtoActivity,
      'dto': dto,
      'notas': notas
    }
    try {
      const studentReturned = await studentsService.putStudent(studentDTO)
      console.log('Estudiante devuelto: ', studentReturned)
      setCurrentStudent(studentReturned._id)
      setLastScreen('estudiantesCrear')
      setScreen('estudianteFicha')
    } catch (error) {
      console.log(error)
      alert(error.response.data.error)
    }
  }

  const handleAddTelephone = (e) => {
    e.preventDefault()
    // console.log('EVENTO: ', e)
    setTelephoneArray(telephoneArray.concat(telephone))
    setTelephone('')
  }

  const deleteTelephone = (e) => {
    // console.log('EVENTO: ', e.target.closest("p").textContent)
    const toDelete = e.target.closest("p").textContent
    setTelephoneArray(telephoneArray.filter(elem=>elem !== toDelete))
  }
  const clasesNamesFilter = () => {
    // console.log('ClasesNames: ', clasesNames)
    // console.log('DESDE FUNCION DE FILTRO ClasesSeleccionadas: ', clasesSelecteds)
    const clasesSelectedsNombres = clasesSelecteds.map(clase => clase.nombre)
    const clasesFiltradas = clasesNames.filter(elem => !clasesSelectedsNombres.includes(elem))
    // console.log('DESDE FUNCION DE FILTRO Filtradas: ', clasesFiltradas)
    return clasesFiltradas
  }
  const deleteClase = (e) => {
    const toDelete = e.target.closest("p").textContent
    // console.log('ELEMENTO A BORRAR: ', toDelete)
    setClasesSelecteds(clasesSelecteds.filter(elem=>elem.nombre !== toDelete))
  }
  const deleteNota = (e) => {
    const toDelete = e.target.closest("u").textContent
    console.log('ELEMENTO A BORRAR: ', toDelete)
    setNotas(notas.filter(elem=>{
      console.log('elem: ', elem)
      console.log('toDel: ', toDelete)
      console.log('Si o No: ', !elem.includes(toDelete))
      return !elem.includes(toDelete)
    }))
    console.log('lista de notas: ', notas)
  }
  const setDias = (clase, dias) => {
    // console.log('clase: ', clase, ' dias: ', dias)
    setClasesSelecteds(clasesSelecteds.map(elem => elem.nombre === clase.nombre ? {nombre: clase.nombre, dias: Number(dias)} : elem))
    // console.log('Clases SELECTEDS despues de editar dias: ', clasesSelecteds)
  }

  const getFecha = () => {
    const date = new Date()
    // const date = fecha
    const anno = date.getFullYear()
    const mes = (date.getMonth()+1).toString().padStart(2, '0')
    const dia = date.getDate().toString().padStart(2, '0')
    // console.log('Fecha formateada: ', `${anno}-${mes}-${dia}`)
    return `${anno}-${mes}-${dia}`
  }


  return (
    <>
      {/* {console.log('Estado del Array de telefonos: ', telephoneArray)} */}
      <form onSubmit={(event) => {
        event.preventDefault()
        handleSubmit()
      }}>

        {/* NOMBRE */}
        <div className="formulario-group">
          <p>nombre del alumno</p>
          <input
            type="text"
            name="StudentName"
            value={studentName}
            onChange={e=>setStudentName(e.target.value)}
          /><span>*</span>
        </div>
        {/* TUTOR */}
        <div className="formulario-group">
          <p>nombre del tutor</p>
          <input
            type="text"
            name="TutorName"
            value={tutorName}
            onChange={e=>setTutorName(e.target.value)}
          />
        </div>
        {/* TELEFONOS */}
        <div className="formulario-group">
          <p>teléfonos</p>
          <input
            type="number"
            name="Telephone"
            value={telephone}
            onChange={e=>setTelephone(e.target.value)}
          />
          <span>
            <button 
              className="button-52-telephone"
              onClick={handleAddTelephone}
            >
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
            </button>
          </span>
          {
            telephoneArray.map(elem => {
              return (
                <>
                  <div onClick={deleteTelephone}>
                    <p  
                      className="telephoneadded">
                      {elem}
                      <span><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                    </p>
                  </div>
                </>
              )
            })
          }
        </div>

        {/* FECHA DE NACIMIENTO */}
        <div className="formulario-group">
          <p>fecha de nacimiento</p>
          {/* <label for="start">Start date:</label> */}
        <input 
          onChange={(e)=>{
            setBirthDate(e.target.value)
          }}
          type="date" id="start" name="trip-start"
          value={birthDate}
          min="1940-01-01" max="2025-12-31"/>
        </div>

        <br></br>
        <hr></hr>
        
        
        {/* ARRAY PARA CLASES */}
        <div className="formulario-group">
        <div className="select-wrapper">
          <select 
            className="button-52-select"
            // onChange={e => setClasesSelecteds(clasesSelecteds=>clasesSelecteds.concat(e.target.value))}
            onChange={e => setClasesSelecteds(clasesSelecteds=>clasesSelecteds.concat({nombre: e.target.value, dias: 1}))}
          >
            {console.log('Array clasesSelecteds: ', clasesSelecteds)}
            <option selected value="">todas las clases</option>
            {
              // clasesNames.map(clase=><option key={clase} value={clase}>{clase}</option>)
              clasesNamesFilter().map(clase=><option key={clase} value={clase}>{clase}</option>)
            }
          </select>
        </div>
          {
            clasesSelecteds.map(elem => {
              return (
                <>
                  <div onClick={deleteClase}>
                    <p  
                      className="classadded">
                      {elem.nombre}
                      <span><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                    </p>
                  </div>
                  {
                    (elem.nombre === 'fitness' || elem.nombre === 'tonificacion')
                      ? <>
                          <select
                            className="button-52-select-dias"
                            onChange={(e)=>setDias(elem, e.target.value)}
                          >
                            <option value="1" selected={elem.dias===1 && '1 días'}>1 días</option>
                            <option value="2" selected={elem.dias===2 && '2 días'}>2 días</option>
                            <option value="3" selected={elem.dias===3 && '3 días'}>3 días</option>
                            <option value="4" selected={elem.dias===4 && '4 días'}>4 días</option>
                            <option value="5" selected={elem.dias===5 && '5 días'}>5 días</option>
                          </select> 
                        </>
                      : ''
                  }
                </>
              )
            })
          }
        </div>
        <br></br>
        <hr></hr>
        <br></br>

        {/* NOTAS */}
        <p>Notas</p>
        {
          notas 
            ? notas.map(nota=>{
                return(
                  <div className='nota-bloque'>
                    <u onClick={deleteNota} className='nota-fila'>{nota.split('_')[0]}<span><i class="fa fa-trash-o" aria-hidden="true"></i></span></u>
                    <p className='nota-fila'>{nota.split('_')[1]}</p>
                  </div>
                )
              })
            : ''
        }
        <br></br>
     
        {
          !visibilityChecks
            ? <p onClick={()=>setVisibilityChecks(value=>!value)}>más opciones...<i class="fa fa-caret-down" aria-hidden="true"></i></p>
            : <>
                {/* DESCUENTOS */}
                <div className="formulario-group">
                  <div className='cuadro-opciones-filtro-op'>
                    <input
                      onClick={()=>setDtoFamily(value=>!value)} 
                      type="checkbox" id="switch3" checked={dtoFamily}/>
                    <label for="switch3">Toggle</label>
                    <span className="etiqueta-estados-nuevo-alumno">DTO familia</span>
                  </div>
                </div>
                <br></br>
                <div className="formulario-group">
                  <div className='cuadro-opciones-filtro-op'>
                    <input
                      onClick={()=>setDtoActivity(value=>!value)} 
                      type="checkbox" id="switch4" checked={dtoActivity}/>
                    <label for="switch4">Toggle</label>
                    <span className="etiqueta-estados-nuevo-alumno">DTO actividad</span>
                  </div>
                </div>
                <br></br>
                <div className="formulario-group">
                  <div className='cuadro-opciones-filtro-op'>
                    <input
                      onChange={e=>setDto(e.target.value)}
                      className="input-descuento-general"
                      value={dto > 0 ? dto : ''} 
                      id="switch5" checked={dtoActivity}/>
                    {/* <label for="switch5">Toggle</label> */}
                    <span className="etiqueta-estados-nuevo-alumno">DTO general</span>
                  </div>
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                {/* ESTADOS ECONOMICO Y ACTIVO DEL ESTUDIANTE */}
                <div className="formulario-group">
                  <div className='cuadro-opciones-filtro-op'>
                    <input
                      onClick={()=>setActive(value=>!value)} 
                      type="checkbox" id="switch" checked={active}/>
                    <label for="switch">Toggle</label>
                    <span className="etiqueta-estados-nuevo-alumno">activo</span>
                  </div>
                </div>
                <br></br>
                {/* <div className="formulario-group">
                  <div className='cuadro-opciones-filtro-op'>
                    <input
                      onClick={()=>setCorrientePago(value=>!value)} 
                      type="checkbox" id="switch2" checked={corrientePago}/>
                    <label for="switch2">Toggle</label>
                    <span className="etiqueta-estados-nuevo-alumno">corriente de pago</span>
                  </div>
                </div> */}
                <p onClick={()=>setVisibilityChecks(value=>!value)}>menos opciones...<i class="fa fa-sort-asc" aria-hidden="true"></i></p>
              </>
        }
        
        


        {/* CREAR */}
        <button
          type="submit" 
          className="button-52-formulario"
        >
          Aplicar
        </button>

      </form>
    </>
  )
}

export default StudentEditForm