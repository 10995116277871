const getURL = () => {
  // return 'http://localhost:3003/api/'
  // return 'https://18.159.102.65:3003/api/'
  // return 'https://back.dev.elitedanza.com:3003/api/'
  return 'https://back2.dev.elitedanza.com:3003/api/'
  // return 'https://api.garredev.online:3003/api/'
}

export default {
  getURL
}