import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
// import Blog from './components/Blog'
import LogOutButton from './components/LogOutButton'
import LoginForm from './components/LoginForm'
// import BlogForm from './components/BlogForm'
import StudentComponent from './components/StudentComponent'
import StudentCard from './components/StudentCard'
import OperationCard from './components/OperationCard'
import OperationsStudent from './components/OperationsStudent'
import MessageAlert from './components/MessageAlert'
import GoBackButton from './components/GoBackButton'
// import Toggable from './components/Toggable'

// import blogService from './services/blogs'
import generadorMensuales from './utils/GeneradorMensuales'
import utilMessageAlert from './utils/MessageAlert'
import OperationsListViewer from './components/OperationsListViewer'
import StudentCreationForm from './components/StudentCreationForm'
import InfoClasesComponent from './components/InfoClasesComponent'
import clasesCalculator from './utils/ClasesCalculator'
import OperationCrearBoton from './components/OperationCrearBoton'
import OperationCreationForm from './components/OperationCreationForm'
import CortinaCrearMensualidades from './components/CortinaCrearMensualidades'
import Administracion from './components/Administracion'
import StudentEditForm from './components/StudentEditForm'
import NotesListViewer from './components/NotesListViewer'
import SpinLoad from './components/SpinLoad'
// import './styles/style.css'


const App = forwardRef((props, refs) => {
  // const [blogs, setBlogs] = useState([])
  
  // const [user, setUser] = useState(null)
  const user = props.user
  const setUser = props.setUser
  
  const [screen, setScreen] = useState('estudiantesListado')
  const [lastScreen, setLastScreen] = useState('estudiantesListado')
  const [messageAlert, setMessageAlert] = useState(null)
  const [errorScreenMessage, setErrorScreenMessage] = useState({})
  const [currentStudent, setCurrentStudent] = useState('')
  const [currentOperation, setCurrentOperation] = useState('')
  const [clasesPreparadas, setClasesPreparadas] = useState(false)
  // const [ execCrearMensualidades, setExecCrearMensualidades ] = useState(false)

  useEffect(()=>{
    setScreen('estudiantesListado')
    setLastScreen('estudiantesListado')
  },user)
  // inicializamos CON effect
  useEffect(()=>{
    clasesCalculator
      .inicializar()
      .then(result=>{
        setClasesPreparadas(result)
        console.log('11111111111')
      })
  },[])
  

  // Comprobamos si debemos llamar al script para generar operaciones del mes actual
  // Activamos el estado que renderiza el componente que hara una cortina con spinLoad y llamará al script
  // useEffect(()=>{
  //   generadorMensuales
  //     .comprobar()
  //     .then(response => {
  //       if(response){
  //         console.log('activamos el estado para renderizar comp que hará la acción y advertirá al usuario')
  //         setExecCrearMensualidades(true)
  //       }
  //     })
  // },[])

  // Manejo de REFS desde Layout (componente PADRE)
  const changeScreen = (value) => {
    // console.log('Desde el cambiador de pantallas en APP: ', value)
    setScreen(value)
  }
  useImperativeHandle(refs, ()=>{
    return {
      changeScreen
    }
  })

  ///////// En caso de error de autorización. Borrar localStorage y volver a login //////////////
  const resetCredentials = () => {
    localStorage.clear()
    setUser(null)  
  }
  ///////// En caso de error de autorización. Borrar localStorage y volver a login //////////////


  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  ///////// Pantalla de LOGIN //////////////
  if(user===null){
    return(
      <>
        <MessageAlert notification={messageAlert}/>
        <h3>
          <img id='logoImage' src='https://www.elitedanza.com/images/logo-01-semi.png' />
          elite danza
        </h3>
        <LoginForm 
          setUser={setUser}
          setMessage={setMessageAlert}
        />
      </>
    )
  }
  ///////// Pantalla de LOGIN //////////////
///////////////////////////////////////////////////

///////////////////////////////////////////////////
  ///////// Pantallas de LISTADOS //////////////
  if(screen==='estudiantesListado' && clasesPreparadas)
    return(
      <>
        {/* {clasesCalculator.status()} */}
        {console.log('222222')}
        <MessageAlert notification={messageAlert}/>
        <h4>buscar alumno:</h4>       
        <StudentComponent 
          user={user}
          setScreen={setScreen}
          setLastScreen={setLastScreen}
          setErrorScreenMessage={setErrorScreenMessage}
          setCurrentStudent={setCurrentStudent}
        />
      </>
    )
  ///////////////////////////////////////////////////
  if(screen==='estudianteFicha'){
    // console.log('estudiante actual', currentStudent)
    return (
      <>
        <h4>ficha de alumno</h4>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <StudentCard 
          currentStudent={currentStudent} 
          setScreen={setScreen}
          user={user}
        />
        {
          (user.roles === 'admin' || user.roles === 'directora') &&
            <OperationCrearBoton 
              currentStudent={currentStudent}
              setScreen={setScreen}
              setLastScreen={setLastScreen}
            />
        }
        <OperationsStudent 
          student={currentStudent} 
          setScreen={setScreen} 
          setCurrentOperation={setCurrentOperation}
          setLastScreen={setLastScreen}
          user={user}
        />
        <NotesListViewer 
          currentStudent={currentStudent}
          user={user}
        />
      </>
    )
  }
  ///////////////////////////////////////////////////
  if(screen==='operacionFicha'){
    // console.log('operacion actual', currentOperation)
    return (
      <>
        <h4>detalle operación</h4>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <OperationCard currentOperation={currentOperation} setCurrentStudent={setCurrentStudent} setScreen={setScreen} setLastScreen={setLastScreen} />
      </>
    )
  }
  ///////////////////////////////////////////////////
  if(screen==='operacionesListado')
    return(
    <>
      <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
      <h4>listado de operaciones</h4>
      <OperationsListViewer setScreen={setScreen} setCurrentOperation={setCurrentOperation} setLastScreen={setLastScreen} user={user} />
    </>
    )
  ///////// Pantallas de LISTADOS //////////////
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  ///////// Pantallas de CREACION //////////////
  if(screen==='estudiantesCrear')
    return(
      <>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <h4>nuevo alumno</h4>
        <StudentCreationForm 
          setScreen={setScreen} 
          setLastScreen={setLastScreen} 
          setCurrentStudent={setCurrentStudent}
        />
      </>
    )
  ///////////////////////////////////////////////////
  if(screen==='operacionesCrear')
    return(
      <>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <h4>nueva operación</h4>
        <OperationCreationForm 
          currentStudent={currentStudent}
          setCurrentOperation={setCurrentOperation}
          setLastScreen={setLastScreen}
          setScreen={setScreen}
        />
      </>
    )
  ///////// Pantallas de CREACION //////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
  ///////// Pantallas de EDICION //////////////
  if(screen==='estudiantesEditar')
    return(
      <>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <h4>editar alumno</h4>
        <StudentEditForm 
          setScreen={setScreen} 
          setLastScreen={setLastScreen} 
          setCurrentStudent={setCurrentStudent}
          currentStudent={currentStudent}
        />
      </>
    )
  ///////// Pantallas de EDICION //////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
  ///////// Pantallas de INFO //////////////
  if(screen==='infoClases')
    return(
      <>
        <GoBackButton setScreen={()=>setScreen(lastScreen)}/>
        <h4>información de las clases</h4>
        <InfoClasesComponent />
      </>
    )
  ///////// Pantallas de INFO //////////////
///////////////////////////////////////////////////

///////////////////////////////////////////////////
/////////////// PANTALLA DE ADMIN /////////////////
  if(screen==='administracion')
    return(
      <>
        <h4>administración</h4>
        <Administracion />
      </>
    )
/////////////// PANTALLA DE ADMIN /////////////////
///////////////////////////////////////////////////

///////////////////////////////////////////////////
  ///////// Pantallas de ERROR //////////////
  if(screen==='pantallaError')
    return(
      <div>
        <h2>Error</h2>
        <p>Nombre del error: {errorScreenMessage.name}</p>
        <p>Mensaje del error: {errorScreenMessage.message}</p>
        <p>{errorScreenMessage.description}</p>
        <a href="https://wa.me/34646739125">Chat con Rafa</a>
        <p>
          O vuelve a 
          <button
            onClick={resetCredentials}
          >
            Loggear
          </button>
          de nuevo
        </p> 
      </div>
    )
  ///////// Pantallas de ERROR //////////////
  return(
    <SpinLoad/>
  )
})

export default App