const GoBackButton = ({setScreen}) => {

  return(
    <button 
      className="button-return-52"
      onClick={setScreen}
    >
        volver
      </button>
  )
}

export default GoBackButton