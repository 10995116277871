import axios from "axios";
import tokenExtractor from "../utils/TokenExtractor"
import Constantes from '../utils/Constantes'

// const baseUrl = '/api/mensualidades'
// const baseUrl = 'https://api.garredev.online:3003/api/mensualidades'
// const baseUrl = 'http://18.159.102.65:3003/api/mensualidades'
const baseUrl = Constantes.getURL() + 'mensualidades'

const getMensualidades = async () => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl, config)
    return response.data
  } catch (error) {
    throw error
  }
}

const getMensualidad = async (mes) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get((baseUrl+'/'+mes), config)
    return response.data
  } catch (error) {
    throw error
  }
}

const postMensualidad = async (mensualidadDTO) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.post(baseUrl, mensualidadDTO, config)
    return response.data
  } catch (error) {
    throw error
  }
}



export default { 
  getMensualidades,
  getMensualidad,
  postMensualidad
}