import { useEffect, useState } from "react"
import studentService from '../services/students'
import StudentFilter from "./StudentFilter"


const StudentComponent = ({user, setScreen, setLastScreen, setErrorScreenMessage, setCurrentStudent}) => {
  
  const [students, setStudents] = useState([])
  // const [search, setSearch] = useState('')
  
  const {roles} = user

  useEffect(()=>{
    // console.log('desde el efecto, traer estudianters')
    studentService
      .getAll()
      .then(students => {
        setStudents(students)
        setLastScreen('estudiantesListado')
      })
      .catch((error) => {
        console.log(error)
        setErrorScreenMessage({
          'name':error.name,
          'message':error.message,
          'description':<p>No autorizada la consulta de Estudiantes.<br/>Contactar con el Admin: <i>GarreDev</i></p>
        })
        setScreen('pantallaError')
      })
    document.getElementById('campoFocus').focus()
  }, [])


  // console.log('roles: ', roles)
  // console.log('Lista de estudiantes: ', students)
  // console.log('')

  return (
    <>
      <StudentFilter students={students} setScreen={setScreen} setCurrentStudent={setCurrentStudent} />
    </>
  )
}

export default StudentComponent