import { useEffect, useState } from 'react'
import StudentService from '../services/students'
import clasesCalculator from '../utils/ClasesCalculator'

import '../styles/styleStudentCard.css'

// import OperationsStudent from './OperationsStudent'

const StudentCard = ({currentStudent, setScreen, user}) => {
  // console.log('Hola desde Student Card: ', currentStudent)
  const [student, setStudent] = useState(null)
  const [telphonesVisibility, setTelephonesVisibility] = useState(false)
  // console.log('student: ', student)

  useEffect(()=>{
    StudentService
      .getStudent(currentStudent)
      .then(result => {
        // console.log('resultado: ',result)
        setStudent(result)
      })
      .catch((error) => {
        console.log(error)
        // eslint-disable-next-line no-undef
        // setErrorScreenMessage({
        //   'name':error.name,
        //   'message':error.message,
        //   'description':<p>No autorizada la consulta de Estudiantes.<br/>Contactar con el Admin: <i>GarreDev</i></p>
        // })
        // eslint-disable-next-line no-undef
        setScreen('pantallaError')
      })
  },[])


  if(student){
    clasesCalculator.calcular(student)
    return(
      <>
        <div className="ac-card">
          <div className="ac-card-image"></div>
          <div className="ac-card-info">
            <p 
              onClick={()=>{
                if(user.roles === 'admin' || user.roles === 'directora'){
                  setScreen('estudiantesEditar')
                }
              }}
              className={`nombre-ficha ${student.active? '' : 'disabled'}`}>
              <strong id="name">
                <i class="fa fa-pencil boton-editar-estudiante" aria-hidden="true"></i>
                {student.name}
              </strong>
              {student.state !== 'corriente'? <span id="pagoPendiente"><i className="fa fa-credit-card" aria-hidden="true"></i></span> : ''}
              {!student.active? <span id="estudiante-inactivo"><i className="fa fa-user-times" aria-hidden="true"></i></span> : ''}
              <br/>
              {
               student.tutor
                 ? <>
                     <span>hija/o de {student.tutor}</span>
                   </>
                 : ''
              }
            </p>
            {
              !student.active &&
                <p className='disabled'>alumno inactivo</p>
            }
            {/* <p id="partTime" className="hide">PART TIME</p> */}
            {/* <p className="ac-card-date">{student.name}</p> */}
            
            {
              student.telephones.length > 0 &&
                <>
                  <p onClick={()=>{setTelephonesVisibility(value=>!value)}}>
                    teléfonos:
                  </p>
                  <div className='telephones-list'>
                    {
                      telphonesVisibility
                      ? student.telephones.map(elem => {
                          const hrefTelephone = 'tel:34'+elem
                          // console.log('ref: ', hrefTelephone)
                          return (
                            <button className='button-52-telephones' 
                              onClick={()=>window.location.href = hrefTelephone}
                            >
                              <span><i class="fa fa-volume-control-phone" aria-hidden="true"></i>{elem}</span>
                            </button>
                          )
                        })  
                      : ''
                    }
                  </div>
                  <hr/>
                </>
            }
            
            {/* Precios con ClasesCalculator */}
            {
              clasesCalculator.getClasesArray().map(clase => {
                return (
                  <p className='clase-linea-ficha'>{clase.nombre}<span className='clase-linea-ficha-margen'>{clase.mensualidadOrigen}€</span></p>
                )
              })
            }
            {
              student.clases.length > 0 &&
                <>
                  <hr></hr>
                  <p className='clase-linea-ficha'>subtotal<span className='clase-linea-ficha-margen'>{clasesCalculator.getMensualidadOrigen()}€</span></p>
                </>
                
            }
            {/* <hr></hr> */}
            {
              student 
                && student.dtoActivity 
                  &&  <p className='clase-linea-ficha'>dto 2ªact <span className='clase-linea-ficha-margen'>-{clasesCalculator.getDescuentos().dtoActivity}%</span></p>                    
            }
            {
              student 
                && student.dtoFamily 
                  && <p className='clase-linea-ficha'>dto familiar <span className='clase-linea-ficha-margen'>-{clasesCalculator.getDescuentos().dtoFamily}%</span></p>
            }
            {
              student 
                && student.dto > 0
                  && <p className='clase-linea-ficha'>dto <span className='clase-linea-ficha-margen'>-{student.dto}%</span></p>
            }
            
          </div>
          {/* <img className="ac-icon" src="https://imdac.github.io/images/codepen/AC_ICON_1C_BLK_70x58.png" /> */}
          <img id='logoImage' src='https://www.elitedanza.com/images/logo-01-semi.png' />
          <div className="ac-card-footer">
          
            {/* Precio con claseCalculator */}
            {/* <h6><span className='importe-mensual'>{student.mensualidad? `${student.mensualidad}€/mes` : ''}</span></h6> */}
            {/* {console.log('Mensualidad: ', clasesCalculator.getMensualidad())} */}
            <h6>
              <span className='importe-mensual'>
                {
                  student.clases.length > 0
                    ? 'Total: ' + clasesCalculator.getMensualidad() ? (clasesCalculator.getMensualidad() + ' €/mes') : ''
                    : ''
                }
              </span>
            </h6>
          </div>
        </div>
        
        {/* <OperationsStudent student={student}/> */}
        
      </>  
    )
  }
}

export default StudentCard