const OperationCrearBoton = ({currentStudent, setScreen, setLastScreen}) => {
  // console.log('student: ', currentStudent)
  return(
    <>
      <button 
        className="button-52"
        onClick={()=>{
          setScreen('operacionesCrear')
          setLastScreen('estudianteFicha')
          // setCurrentStudent(operation.student._id)
        }}
      >
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        <span>Crear operacion</span>
      </button>
    </>
  )
}

export default OperationCrearBoton 