import getFechas from './GetFechas'
import mensualidadService from '../services/mensualidades'
import operationsService from '../services/operations'
import studentsService from '../services/students'
import clasesCalculator from './ClasesCalculator'

const comprobar = async () => {

  const mensualidadActual = getFechas.getMensualidadActual()

  const mensualidadesServer = await mensualidadService.getMensualidades()

  try {
    const mensualidadServer = await mensualidadService.getMensualidad(mensualidadActual)
    return false
  } catch (error) {
    if(error.response.status === 404){
      return true
    }
    return false
  }
}

const getDetalles = (student) => {
  let detallesFactura = []

  // clases
  clasesCalculator.getClasesArray().map(elem=>detallesFactura = detallesFactura.concat(elem.nombre + ': ' + elem.mensualidadDeClase + '€'))
  
  // descuentos
  if(student){
    if(student.dtoActivity)
      detallesFactura = detallesFactura.concat('descuento por 2ª actividad: ' + clasesCalculator.getDescuentos().dtoActivity + '%')
    if(student.dtoFamily)
      detallesFactura = detallesFactura.concat('descuento por familiar: ' + clasesCalculator.getDescuentos().dtoFamily + '%')
    if(student.dto)
      detallesFactura = detallesFactura.concat('descuento: ' + student.dto + '%')
  }
  // total
  detallesFactura = detallesFactura.concat('Total: ' + clasesCalculator.getMensualidad() + '€')
  
  return detallesFactura
}

const crearMensuales = async (mes) => {

  const mensualidadParaOperaciones = mes ? mes : getFechas.getMensualidadActual()
  console.log('La mensualidad a generar es: ', mensualidadParaOperaciones)

  console.log('Estamos creando mensuales')
  let studentsArray = await studentsService.getAll()
  console.log('Estudiantes ARRAY: ', studentsArray)
  studentsArray = studentsArray.filter(elem=>elem.clases.length && elem.active)
  console.log('FILTRO quitamos los SINCLASES: ', studentsArray)
  studentsArray = studentsArray.filter(elem=>{
    const operacionesNombre = elem.operations.map(operation=>operation.name)
    return !operacionesNombre.includes(mensualidadParaOperaciones)
  })
  console.log('FILTRO quitamos los que ya tienen operación del mes: ', studentsArray)

  for (const student of studentsArray) {
    console.log('nombre: ', student.name)

    clasesCalculator.calcular(student)

    const operationDTO = {
      name: mensualidadParaOperaciones,
      opType: 'mensual',
      blocking: true,
      paid: false,
      detailed: getDetalles(student),
      price: clasesCalculator.getMensualidad(),
      description: 'operación generada automáticamente',
      studentId: student._id
    }
    try {
      await operationsService.postOperation(operationDTO)
      // console.log('GUARDAMOS OPERACION: ', operationDTO)
    } catch (error) {
      console.log('No se ha podido generar operación mensual para ', student.name, 'para el ', mensualidadParaOperaciones)
    }
  }
  // try {
  //   await mensualidadService.postMensualidad({"mes":mensualidadParaOperaciones})
  // } catch (error) {
  //   console.log('No se ha podido guardar la mensualidad en la colección de mensualidades')
  // }
}

const borrarMensuales = async (mes) => {
  console.log('MES: ', mes)
  const operationsArray = await operationsService.getOperationsByName(mes)
  console.log('RESUELTO...')
  console.log('RESPUESTA en utils/genMens: ', operationsArray)
  for (const op of operationsArray) {
    try {
      await operationsService.removeOperation(op._id)
    } catch (error) {
      throw error
    }
  }
}

const aplicarDtoMasa = async (descuentoMasa) => {
  let students = await studentsService.getAll()
  console.log('Estudiantes obtenidos: ', students)
  students = students.filter(student => student.active)
  console.log('Estudiantes filtrados: ', students)
  for (const student of students) {
    const studentDTO = {
      '_id': student._id,
      // 'name': studentName,
      // 'tutor': tutorName,
      // 'telephones': telephoneArray,
      // 'birthDate': birthDate,
      // 'clases': clasesSelecteds,
      // 'state': corrientePago ? 'corriente' : 'moroso',
      // 'active': active,
      // 'dtoFamily': dtoFamily,
      // 'dtoActivity': dtoActivity,
      'dto': descuentoMasa ? descuentoMasa : 0,
      // 'notas': notas
    }
    console.log('estudianteDTO: ', studentDTO)
    try {
      await studentsService.putStudent(studentDTO)
    } catch (error) {
      console.log('Error ocurrido: ', error)
    }
  }
}

export default {
  comprobar,
  crearMensuales,
  borrarMensuales,
  aplicarDtoMasa
}