import clasesCalculator from "../utils/ClasesCalculator"

const StudentsListViewer = ({studentsFiltered, setScreen, setCurrentStudent}) => {

  const debeMensualidad = (elem) => {
    return elem.operations.some(op=>(op.opType==='mensual' && !op.paid))
  }

  // console.log('Desde el Visor de estudiantes filtrados: ', studentsFiltered)
  if(studentsFiltered.length === 0)
    return <p>No existen alumnos con ese nombre</p>
  if(studentsFiltered.length < 1500)
    return (
      <>
        {
          studentsFiltered.map(student => {
            // Llamamos al calculator de clases para tener los datos de precios actualizados
            clasesCalculator.calcular(student)

            return (
              <>
                <p className={`student-row ${student.active? '' : 'disabled'} ${debeMensualidad(student) ? 'deuda' : ''}`} 
                  key={student._id}
                  onClick={()=>{
                    setScreen('estudianteFicha')
                    setCurrentStudent(student._id)
                  }}
                >
                  {student.name}
                  {/* {student.dto} */}
                  {/* {
                    student.state !== 'corriente'
                      ? <span id="pagoPendiente"><i className="fa fa-credit-card" aria-hidden="true"></i></span>
                      : ''
                  }
                  {
                    !student.active
                      ? <span id="estudiante-inactivo"><i className="fa fa-user-times" aria-hidden="true"></i></span>
                      : ''
                  } */}
                  {/* <span className="importe-mensual">{student.mensualidad? `${student.mensualidad}€/mes` : ''}</span> */}
                  <span 
                    className="importe-mensual"
                  >
                    {clasesCalculator.getMensualidad() ? `${clasesCalculator.getMensualidad()}€/m ` : ''}
                  </span>
                  
                </p>
              </>
            )
          })
        }
      </>
    )
  return <p>Demasiado alumnos para mostrar...</p>
}

export default StudentsListViewer