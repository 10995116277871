import { useEffect, useState } from 'react'
import operationsService from '../services/operations'

import CortinaPagarBorrarOp from './CortinaPagarBorrarOp'

const OperationsListViewer = ({setScreen, setCurrentOperation, setLastScreen, user}) => {
  {console.log('USER: ', user)}
  
  const [operations, setOperations] = useState([])
  const [optionsVisibility, setOptionsVisibility] = useState(false)
  const [search, setSearch] = useState('')
  const [paid, setPaid] = useState(false)
  const [notPaid, setNotPaid] = useState(false)
  const [mensuales, setMensuales] = useState(false)
  const [otros, setOtros] = useState(false)

  const [ currentOp, setCurrentOp ] = useState(null)
  const [ cortina, setCortina ] = useState(false)



  useEffect(()=>{
    // console.log('Estudiante: ', student)
    operationsService
      .getOperations()
      .then(operations => {
        // console.log('operaciones devueltas por el servicio en OperationsStudent: ',operations)
        setOperations(operations)
      })
      .catch(error => {
        console.log(error)   
      })
  }, [cortina])

  const filtroListado = () => {
    let filtrado = operations
    if(search)
      filtrado = filtrado.filter(elem => elem.name.toLowerCase().includes(search.toLowerCase()))
    if(paid)
      filtrado = filtrado.filter(elem => elem.paid)
    if(notPaid)
      filtrado = filtrado.filter(elem => !elem.paid)
    // filtrado = filtrado.filter(elem => !elem.paid)
    // filtrado = new Array(filtrado.find(elem => elem.paid))
    if(mensuales)
      filtrado = filtrado.filter(elem => elem.opType === 'mensual')
    if(otros)
      filtrado = filtrado.filter(elem => elem.opType !== 'mensual')

    console.log('Filtrado: ', filtrado)
    return filtrado
  }
  
  // console.log('DESDE OPERATIONSSTUDENToperaciones: ', operations)


  return (
    <>
      <input
        className="button-52"
        value={search}
        onChange={e => setSearch(e.target.value)}
        id={'campoFocus'}
      />
      <span><i className="fa fa-search" aria-hidden="true"></i></span>
      <span>
        <button
          onClick={()=>setOptionsVisibility(value=>!value)} 
          className='button-52-despliegue-filtros-ops'>
          <i class="fa fa-filter" aria-hidden="true"></i>
        </button>
      </span>
      
      {
        optionsVisibility
          ? 
            <>
              <hr></hr>
              {/* BLOQUE pagadas y no pagadas */}
              <div className='cuadro-opciones-filtro-op'>

                <div className='cuadro-opciones-filtro-op-group'>
                  <input
                    onClick={()=>{
                      setPaid(value=>!value)
                      if(notPaid){
                        document.getElementById('switch2').checked = !document.getElementById('switch2').checked
                        setNotPaid(value=>!value)
                      }
                    }} 
                    type="checkbox" id="switch" />
                  <label for="switch">Toggle</label>
                  <span>pagadas</span>
                </div>
                
                <div className='cuadro-opciones-filtro-op-group'>
                  <input 
                    onClick={()=>{
                      setNotPaid(value=>!value)
                      if(paid){
                        document.getElementById('switch').checked = !document.getElementById('switch').checked
                        setPaid(value=>!value)
                      }
                    }} 
                    type="checkbox" id="switch2" />
                  <label for="switch2">Toggle</label>
                  <span>no pagadas</span>
                </div>
                 
              </div>

              <hr></hr>
              {/* BLOQUE MENSUALES Y OTROS */}
              <div className='cuadro-opciones-filtro-op'>

                <div className='cuadro-opciones-filtro-op-group'>
                  <input
                    onClick={()=>{
                      setMensuales(value=>!value)
                      if(otros){
                        document.getElementById('switch4').checked = !document.getElementById('switch4').checked
                        setOtros(value=>!value)
                      }
                    }} 
                    type="checkbox" id="switch3" />
                  <label for="switch3">Toggle</label>
                  <span>mensuales</span>
                </div>
                
                <div className='cuadro-opciones-filtro-op-group'>
                  <input 
                    onClick={()=>{
                      setOtros(value=>!value)
                      if(mensuales){
                        document.getElementById('switch3').checked = !document.getElementById('switch3').checked
                        setMensuales(value=>!value)
                      }
                    }} 
                    type="checkbox" id="switch4" />
                  <label for="switch4">Toggle</label>
                  <span>otros</span>
                </div>
              </div>

              <hr></hr>
            </>
          : ''

      }
      {
          cortina && 
            <CortinaPagarBorrarOp 
              setCortina={setCortina}
              currentOp={currentOp}
              user={user}
            />
        }
      {
        
        // operations.map((op) => {
        filtroListado().map((op) => {
          return (
            <p className={`operation-row ${op.paid? 'paid' : 'not-paid'}`}
              key={op._id}
              
            >
              <span 
                onClick={()=>{
                  setScreen('operacionFicha')
                  setLastScreen('operacionesListado')
                  setCurrentOperation(op._id)
              }}>
                {op.name.split('-')[1].length===2?`${op.name} (mensual)`:op.name}
              </span>

              {/* {
                op.paid
                ? <span className="pagoRealizado">{op.price} € <i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                : <span className="pagoPendiente">{op.price} € <i className="fa fa-credit-card" aria-hidden="true"></i></span>
              } */}
              <span
                onClick={(e)=>{
                  if(user.roles==='admin' || user.roles==='directora' || (user.roles==='profesora' && !op.paid)){
                    setCurrentOp(op)
                    setCortina(true)
                  }
                }} 
                className={op.paid?'pagoRealizado':'pagoPendiente'}
              >
                {op.price} €
              </span>
              

              <br></br>
              {op.student.name}
            </p>
          )
        })
      }
    </>
  )
    // <p className={`student-row ${student.active? '' : 'disabled'}`} 
    // <p className={`student-row`} 
    //   key={operation._id}
    //   onClick={()=>{
    //     setScreen('estudianteFicha')
    //     setCurrentStudent(student._id)
    //   }}
    // >
    //   {student.name}
    //   {
    //     student.state !== 'corriente'
    //       ? <span id="pagoPendiente"><i className="fa fa-credit-card" aria-hidden="true"></i></span>
    //       : ''
    //   }
    //   {
    //     !student.active
    //       ? <span id="estudiante-inactivo"><i className="fa fa-user-times" aria-hidden="true"></i></span>
    //       : ''
    //   }
    // </p>
}

export default OperationsListViewer