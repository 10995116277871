import axios from 'axios'
import Constantes from '../utils/Constantes'

// const baseUrl = 'http://localhost:3003/api/login'
// const baseUrl = 'http://192.168.10.7:3000/api/login'
// const baseUrl = 'https://api.garredev.online:3003/api/login'
// const baseUrl = 'http://18.159.102.65:3003/api/login'
const baseUrl = Constantes.getURL() + 'login'


const login = async (credentials) => {
  // console.log('Credenciales recibidas: ', credentials)
  const response = await axios.post(baseUrl, credentials)
  // console.log('Respuesta del servidor: ', response.data)
  // return 'Hola desde servicio de login'
  return response.data
}

export default login