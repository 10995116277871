import axios from "axios";
import tokenExtractor from "../utils/TokenExtractor";
import Constantes from '../utils/Constantes'

// const baseUrl = '/api/operations'
// const baseUrl = 'https://api.garredev.online:3003/api/operations'
// const baseUrl = 'http://18.159.102.65:3003/api/operations'
const baseUrl = Constantes.getURL() + 'operations'

const getOperations = async () => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl, config)
    return response.data
  } catch (error) {
    throw error
  }
}

const getOperation = async (operationId) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    // console.log('XXXXX: ', `${baseUrl}/${operationId}`)
    const response = await axios.get(`${baseUrl}/${operationId}`, config)
    // console.log('operacion obtenida desde servicio: ', response.data)
    return response.data
  } catch (error) {
    throw error
  } 
}

const getOperationsbyStudent = async (studentId) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    // console.log('FABRICACION DE LA URL: ', `${baseUrl}/bystudent/${studentId}`)
    const response = await axios.get(`${baseUrl}/bystudent/${studentId}`, config)
    // console.log('lista de operaciones del estudiante: ', response.data)
    return response.data
  } catch (error) {
    // console.log('XXXXXXXXXXXXXXXXXXXXXXXX')
    throw error
  }
}

const getOperationsByName = async (name) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    console.log('DESDE OPSERVICE-GETOPBYNAME')
    const response = await axios.get(`${baseUrl}/byname/${name}`, config)
    // console.log('RESUELTO...')
    // console.log('RESPUESTA en OP SERVICE: ', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const postOperation = async (operacionDTO) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const result = await axios.post(baseUrl, operacionDTO, config)
    return result.data
  } catch (error) {
    throw error
  }
  return operacionDTO
}

const putPagarOperation = async (opDTO) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  // console.log('opDTO: ', opDTO)
  // console.log('Url: ', `${baseUrl}/${opDTO._id}`)
  try {
    const result = await axios.put(`${baseUrl}/${opDTO._id}`, opDTO, config)
    return result.data
  } catch (error) {
    throw error
  }
}

const removeOperation = async (opId) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    console.log('config: ', config)
    const result = await axios.delete(`${baseUrl}/${opId}`, config)
    return result.data
  } catch (error) {
    throw error
  }
}


export default { 
  getOperations, 
  getOperation, 
  getOperationsbyStudent,
  getOperationsByName,
  postOperation,
  putPagarOperation,
  removeOperation
}