import clasesService from '../services/clases'

let clases = []
let descuentos = {}

// console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX')
// console.log('clases: ', clases)
// console.log('descuentos: ', descuentos)

let mensualidad = 0
let mensualidadOrigen = 0
let claseMasCara = {clase:'', precioMasCaro: 0}
let clasesArray = new Array

const getClasebyName = (name) => clases.find(clase => clase.nombre === name)
const getDescuentos = () => descuentos

const getMensualidad = () => mensualidad
const getMensualidadOrigen = () => mensualidadOrigen
const getClasesArray = () => clasesArray

const inicializar = async () => {
  clases = await clasesService.getClases()
  descuentos = await clasesService.getDescuentos()
  // console.log('CLASES: ', clases)
  // console.log('descuentos: ', descuentos)
  return true
}
const status = () => {
  console.log('---CLASES CALCULATOR STATUS---')
  console.log('VALOR DE CLASES: ', clases)
  console.log('VALOR DE DESCUENTOS: ', descuentos)
}
const calcular = (student) => {
  // Reinicializo variables globales
  mensualidad = 0
  mensualidadOrigen = 0
  claseMasCara = {clase:'', precioMasCaro: 0}
  clasesArray = new Array

  if(student.clases){
    clasesArray = student.clases.map(clase => {
      const clasePlantilla = getClasebyName(clase.nombre)
      // console.log('Clase plantilla: ', clasePlantilla)
    
      const claseDTO = {
        nombre: clasePlantilla.nombre,
        precio: clasePlantilla.precio,
        fijo: clasePlantilla.fijo,
        dias: (clasePlantilla.nombre === 'tonificacion') || clasePlantilla.nombre === 'fitness'
          ? clase.dias
          : 1,
        mensualidadDeClase: 0,
        mensualidadOrigen: 0
      }
      
      claseDTO.mensualidadDeClase = claseDTO.fijo + (claseDTO.dias * claseDTO.precio)
      claseDTO.mensualidadOrigen = claseDTO.fijo + (claseDTO.dias * claseDTO.precio)
      // console.log('Clase DTO: ', claseDTO)
      if(claseDTO.mensualidadDeClase > claseMasCara.precioMasCaro)
        claseMasCara = {clase: claseDTO.nombre, precioMasCaro: claseDTO.mensualidadDeClase}
      // console.log('Clase MAS CARA: ', claseMasCara)
      
      // Calculo de la mensualidad de Student
      // mensualidad = mensualidad + claseDTO.fijo + (claseDTO.dias * claseDTO.precio)
      mensualidad = mensualidad + claseDTO.mensualidadDeClase
      mensualidadOrigen = mensualidad
      // console.log('mensualidad: ', mensualidad)
      // console.log('mensualidadOrig: ', mensualidadOrigen)
      // console.log('XXXXXXXXXXXXXXXXXXXX')

      
      return claseDTO
    })
    
    // DESCUENTOS /////////////////////////////////////////////////////////////
    // Aplicamos descuento de Actividades
    if(student.dtoActivity){
      // reseteamos mensualidad para volver a calcular con descuento
      mensualidad = 0
      clasesArray = clasesArray.map(clase => {
        const claseCambiada = clase.nombre === claseMasCara.clase
          ? clase
          : {...clase, mensualidadDeClase: (clase.mensualidadDeClase * (100-getDescuentos().dtoActivity) / 100)}
        mensualidad = mensualidad + claseCambiada.mensualidadDeClase
        // console.log('claseCalculada: ', claseCambiada)
        return claseCambiada
        })
    }
    // Aplicamos descuento de familia a la mensualidad total
    if(student.dtoFamily)
      mensualidad *= ((100-getDescuentos().dtoFamily) / 100)
    // Aplicamos descuento general
    if(student.dto !== 0 && student.dto){
      // console.log('Mensualidad actual: ', mensualidad)
      // console.log('Descuento general: ', student.dto / 100)
      mensualidad = mensualidad * (1-student.dto /100)
    }
    // Redondeo de mensualidad por si salen decimales de más tras los descuentos
    mensualidad = mensualidad.toFixed(2)
  }
}

export default {
  inicializar,
  status,
  calcular,
  getClasebyName,
  getDescuentos,
  getMensualidad,
  getMensualidadOrigen,
  getClasesArray
}