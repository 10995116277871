import { useEffect, useState } from 'react'
import clasesService from '../services/clases'

const InfoClasesComponent = () => {
  const [ clasesArray, setClasesArray ] = useState([])

  useEffect(()=>{
    clasesService.getClases()
      .then(result => setClasesArray(result))
      .catch(error=>console.log('ERROR al traer clases en InfoClases: ', error))
  }, [])
  
  return(
    <>
      <table>
        <tr>
          <th>clase</th>
          <th>precio</th>
          <th>fijo</th>
        </tr>  
        {
          clasesArray.map(clase=>{
            return(
              <tr>
                <td>{clase.nombre}</td>
                <td>{clase.precio}€</td>
                <td>{clase.fijo}€</td>
              </tr>
            )
          })
        }
      </table>
    </>
  )
}

export default InfoClasesComponent