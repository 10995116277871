import { useEffect, useState } from 'react'
import CortinaPagarBorrarOp from './CortinaPagarBorrarOp'
import operationsService from '../services/operations'

const OperationsStudent = ({student, setScreen, setCurrentOperation, setLastScreen, user}) => {
  // {console.log('XXXXXXXXXXXXXXXXXXXXXXXXX')}
  // console.log('Student: ', student)
  const [ operations, setOperations ] = useState([])
  const [ currentOp, setCurrentOp ] = useState(null)
  const [ cortina, setCortina ] = useState(false)

  useEffect(()=>{
    // console.log('Estudiante: ', student)
    operationsService
      .getOperationsbyStudent(student)
      .then(operations => {
        // console.log('operaciones devueltas por el servicio en OperationsStudent: ',operations)
        setOperations(operations)
      })
      .catch(error => {
        console.log(error)   
      })
  }, [cortina])
  
  // console.log('DESDE OPERATIONSSTUDENToperaciones: ', operations)

  const pagadas = operations.filter(elem=>elem.paid)
  const noPagadas = operations.filter(elem=>!elem.paid)

  console.log('Lista de pagadas: ', pagadas)
  console.log('Lista de NO pagadas: ', noPagadas)


  return (
    <>
      {
        cortina && 
          <CortinaPagarBorrarOp 
            setCortina={setCortina}
            currentOp={currentOp}
            user={user}
          />
      }

      {
        noPagadas.length > 0 &&
          <p>Pendientes de pago</p>
          
      }
      {
        noPagadas.reverse().map((op)=>{
          return (
            <>
              <p className='operation-row not-paid'
                key={op._id}
              >
                <span
                  onClick={()=>{
                    setScreen('operacionFicha')
                    setLastScreen('estudianteFicha')
                    setCurrentOperation(op._id)
                  }}
                >
                  {op.name}
                </span>
                <span 
                  className='pagoPendiente'
                  onClick={(e)=>{
                    if(user.roles==='admin' || user.roles==='directora' || (user.roles==='profesora' && !op.paid)){
                      setCurrentOp(op)
                      setCortina(true)
                    }
                  }}
                >
                  {op.price} €
                </span>
              </p>
            </>
          )
        })
      }
      
      {
        pagadas.length >0 &&
          <p>Pagadas</p>
      }
      {
        pagadas.reverse().map((op)=>{
          return (
            <>
              <p className='operation-row paid'
                key={op._id}
              >
                <span
                  onClick={()=>{
                    setScreen('operacionFicha')
                    setLastScreen('estudianteFicha')
                    setCurrentOperation(op._id)
                  }}
                >
                  {op.name}
                </span>
                <span 
                  className='pagoRealizado'
                  onClick={(e)=>{
                    if(user.roles==='admin' || user.roles==='directora' || (user.roles==='profesora' && !op.paid)){
                      setCurrentOp(op)
                      setCortina(true)
                    }
                  }}
                >
                  {op.price} €
                </span>
              </p>
            </>
          )
        })
      }

    </>
  )
   
}

export default OperationsStudent