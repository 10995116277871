import axios from 'axios'
import tokenExtractor from '../utils/TokenExtractor'
import Constantes from '../utils/Constantes'

// const baseUrl = '/api/students'
// const baseUrl = 'https://api.garredev.online:3003/api/students'
// const baseUrl = 'http://18.159.102.65:3003/api/students'
const baseUrl = Constantes.getURL() + 'students'

const getAll = async () => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.get(baseUrl, config)
    // console.log('lista de estudiantes: ', response.data)
    return response.data
  } catch (error) {
    // throw new Error(error)
    throw error
  }
}

const getStudent = async (id) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const editedUrl = baseUrl + '/' + id
    const response = await axios.get(editedUrl, config)
    // console.log('Estudiante: ', response.data)
    return response.data
  } catch (error) {
    throw error
  }
}

const postStudent = async (studentDTO) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    const response = await axios.post(baseUrl, studentDTO, config)
    return response.data
  } catch (error) {
    throw error
  }
}

const putStudent = async (studentDTO) => {
  const config = {
    headers: {
      Authorization: tokenExtractor()
    }
  }
  try {
    // console.log('XXXX')
    const result = await axios.put(`${baseUrl}/${studentDTO._id}`, studentDTO, config)
    return result.data
  } catch (error) {
    throw error
  }
}

export default { getAll, getStudent, postStudent, putStudent }