import { useEffect, useState } from "react"
import generadorMensuales from "../utils/GeneradorMensuales"
import CortinaCrearMensualidades from "./CortinaCrearMensualidades"
import getFechas from "../utils/GetFechas"


const Administracion = () => {
  
  const [ cortina, setCortina ] = useState(false)
  const [ mensajeCortina, setMensajeCortina ] = useState('')
  const [ ponerBotonCerrarCortina, setPonerBotonCerrarCortina ] = useState(false)
  const [ mes, setMes ] = useState('')
  const [ descuentoMasa, setDescuentoMasa ] = useState(0)

  useEffect(()=>{
    setMes(getFechas.getMensualidadActual())
  },[])

  const handleCrearMensualidades = () => {
    // generadorMensuales
    //   .comprobar()
    //   .then(result=>{
    //     if(result){
    //       console.log('Crear mensuales desde AdminComp: ', result)
    //       setMensajeCortina('Creando mensualidades de todos los alumnos. Por favor, espere...')
    //       setPonerBotonCerrarCortina(false)
    //       setCortina(true)
    //       generadorMensuales
    //         // .crearMensuales('2023-01') // puede aceptar argumento si no quieres usar mes actual
    //         .crearMensuales()
    //         .then(result => {
    //           setCortina(false)
    //         })
    //     }else{
    //       setMensajeCortina('Ya existe la mensualidad en la lista de generadas')
    //       setPonerBotonCerrarCortina(true)
    //       setCortina(true)
    //     }
    //   })
      

    if(window.confirm('Crear Mensualidades para ' + mes + '?')){
      // Aquí lo hacemos sin comprobar colección de mensualidades
      setMensajeCortina('Creando mensualidades de todos los alumnos. Por favor, espere...')
      setPonerBotonCerrarCortina(false)
      setCortina(true)
      generadorMensuales
        // .crearMensuales('2023-03') // puede aceptar argumento si no quieres usar mes actual
        // .crearMensuales()
        .crearMensuales(mes) 
        .then(result => {
          setCortina(false)
        })
    }
  }

  const handleBorrarMensualidades = () => {
    if(window.confirm('BORRAR mensualidades para ' + mes + '????')){
      // Aquí lo hacemos sin comprobar colección de mensualidades
      setMensajeCortina('Borrando mensualidades de todos los alumnos. Por favor, espere...')
      setPonerBotonCerrarCortina(false)
      setCortina(true)
      generadorMensuales
        // .crearMensuales('2023-03') // puede aceptar argumento si no quieres usar mes actual
        // .crearMensuales()
        .borrarMensuales(mes) 
        .then(result => {
          setCortina(false)
        })
    }
  }

  const handleAplicarDescuento = () => {
    if(window.confirm('Aplicar desctuento de '+ descuentoMasa + '% en masa?')){
      console.log('Aplicando descuento...', descuentoMasa)
      setMensajeCortina('Aplicando descuento del ', descuentoMasa, '%. Por favor, espere...')
      setPonerBotonCerrarCortina(false)
      setCortina(true)
      generadorMensuales
        .aplicarDtoMasa(descuentoMasa)
        .then(result => {
          setCortina(false)
        })
    }
  }

  return(
    <>
      {cortina && <CortinaCrearMensualidades 
        setCortina={setCortina}
        mensajeCortina={mensajeCortina}
        ponerBotonCerrarCortina={ponerBotonCerrarCortina}
      />}
      <div className="content">
        <input 
          onChange={(e)=>{
            setMes(e.target.value)
          }}
          type="month" id="start" name="trip-start"
          value={mes} min="1940-01" max="2025-12"
          className='calendario-mes-operacion'
        />
      </div>

      <br></br>
      {/* <hr></hr> */}
      {/* GENERAR MENSUALIDADES */}
      <div className="content">
        <button className='button-52' onClick={handleCrearMensualidades}>
          Generar mensualidad
        </button>
      </div>

      <br></br>
      {/* <hr></hr> */}
      {/* BORRAR MENSUALIDADES */}
      <div className="content">
        <button className='button-52' onClick={handleBorrarMensualidades}>
          BORRAR mensualidad
        </button>
      </div>


      {/* APLICAR DESCUENTO EN MASA, PEJ PARA SEPTIEMBRE QUE SE PAGA LA MITAD */}
      <div className="content">
        <button className='button-52' onClick={handleAplicarDescuento}>
          Aplicar descuento
        </button>
        {/* <p> */}
          <input 
            value={descuentoMasa > 0 ? descuentoMasa : ''}
            onChange={e=>setDescuentoMasa(e.target.value)}
            className="input-descuento-masa"
          ></input> %
        {/* </p> */}
      </div>
    </>
  )
}

export default Administracion