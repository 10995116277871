const BurgerMenu = ({changeScreenOnApp, toggleActive, user}) => {
// console.log('DESDE MENU BURGER: ', user)
  return(
    <div className="burger-menu">
      <div 
        className="burger-icon" id="burgerIcon"
        // onClick={()=>{menu.classList.toggle('active')}}
        onClick={toggleActive}
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      
      <div 
        className="menucontainer" 
        id='menucontainer'
        onClick={toggleActive}
      >
      </div>
      
      <div className="menu" id="menu">
        <ul>
          <li>
            <button
              className="button-52" 
              role="button"
              // className="menu-button" 
              onClick={(e)=>{
                changeScreenOnApp(e.target.name)
                toggleActive()
              }} 
              // onClick={changeScreenOnApp}
              name='estudiantesListado'>
              buscar estudiantes
            </button>
          </li>
          <li>
            <button 
              className="button-52" 
              role="button"
              // className="menu-button" 
              onClick={(e)=>{
                changeScreenOnApp(e.target.name)
                toggleActive()
              }} 
              // onClick={changeScreenOnApp}
              name='operacionesListado'>
              buscar operaciones
            </button>
          </li>
          {
            (user.roles === 'admin' || user.roles === 'directora') &&
              <li>
                <button
                  className="button-52" 
                  role="button"
                  // className="menu-button" 
                  onClick={(e)=>{
                    changeScreenOnApp(e.target.name)
                    toggleActive()
                  }} 
                  // onClick={changeScreenOnApp}
                  name='estudiantesCrear'>
                  nuevo alumno
                </button>
              </li>
          }
          <li>
            <button
              className="button-52" 
              role="button"
              // className="menu-button" 
              onClick={(e)=>{
                changeScreenOnApp(e.target.name)
                toggleActive()
              }} 
              // onClick={changeScreenOnApp}
              name='infoClases'>
              información de clases
            </button>
          </li>
          {
            user.roles === 'admin' &&
              <li>
                <button
                  className="button-52" 
                  role="button"
                  // className="menu-button" 
                  onClick={(e)=>{
                    changeScreenOnApp(e.target.name)
                    toggleActive()
                  }} 
                  // onClick={changeScreenOnApp}
                  name='administracion'>
                  Administración
                </button>
              </li>
          }
        </ul>
      </div>
      
    </div>  
  )
}

export default BurgerMenu