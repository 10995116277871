import { useState, useEffect } from 'react'
import App from './App'
import LogOutButton from './components/LogOutButton'
import Toggable from './components/Toggable'
import BurgerMenu from './components/BurgerMenu'
import clasesCalculator from './utils/ClasesCalculator'

import './styles/style.css'
import './styles/styleFunctions.css'
import './styles/styleLog.css'
import './styles/styleBurgerLateral.css'

import { useRef } from 'react';

const Layout = () => {

  const [user, setUser] = useState(null)

  useEffect(()=>{
    if(window.localStorage.loggedUser){
      // const loggedUser = JSON.parse(window.localStorage.loggedUser)
      // setUser(loggedUser)
      setUser(JSON.parse(window.localStorage.loggedUser))
      // blogService.setNewToken(loggedUser.token)
    }else{
      console.log('NO EXISTE USUARIO')
    }
  }, [])

  const appScreenRef = useRef()

  const changeScreenOnApp = (screen) => {
    // console.log('target: ', e.target.name)
    console.log('target: ', screen)
    appScreenRef.current.changeScreen(screen)
  }
  const toggleActive = ()=>{
    // eslint-disable-next-line no-undef
    menu.classList.toggle('active')
    // eslint-disable-next-line no-undef
    menucontainer.classList.toggle('active')
    // console.log('estilo de menucontainer: ', menucontainer.style.display)
    // menucontainer.style.display === 'none'
    //   ? menucontainer.style.display = 'none'
    //   : menucontainer.style.display = 'block'
  }

  return (
    <>
      {/* {console.log('USUARIO desde Layout: ', user)} */}
      {
        user
          ? <div className="header">
              {
                user
                  ? <p className='login-row'>{user.username}: {user.roles} <LogOutButton setUser={setUser}/></p>
                  : ''
              }
              <BurgerMenu 
                changeScreenOnApp={changeScreenOnApp} 
                toggleActive={toggleActive}
                user={user}
              />
              <h1 className='titulo-logo'>
                <img id='logoImage' src='https://www.elitedanza.com/images/logo-01-semi.png' />
                elite danza
              </h1>
              {
                user
                  ? <p className='login-row'>{user.username}: {user.roles} <LogOutButton setUser={setUser}/></p>
                  : ''
              }
              {/* <p>rama main</p> */}
            </div>
          : ''
      }
      
      <div className="content">
        <App user={user} setUser={setUser} ref={appScreenRef} />
      </div>
    </>
  );
};

export default Layout