import { useState } from 'react'
import loginService from '../services/login'
import blogService from '../services/blogs'
import MessageAlert from '../utils/MessageAlert'


const LoginForm = ({setUser, setMessage}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmitt = async () => {
    try {
      const userReturned = await loginService({username, password})
      console.log('usuario retornado: ', userReturned)
      window.localStorage.setItem('loggedUser', JSON.stringify(userReturned))
      setUser(userReturned)
      blogService.setNewToken(userReturned.token)
      setUsername('')
      setPassword('')
    } catch (error) {
      console.log({error: 'Usuario Incorrecto'})
      MessageAlert(
        setMessage,
        {
          'type': 'error',
          'message': 'Wrong user or password'
        }
      )
    }
  }


  return(
    // <form onSubmit={(event)=>{
    //   event.preventDefault()
    //   handleSubmitt()
    // }}>
    //   <div>
    //     <input
    //       type='text'
    //       name='Username'
    //       value={username}
    //       onChange={({target})=>{setUsername(target.value)}}
    //     />
    //   </div>
    //   <div>
    //     <input
    //       type='password'
    //       name='Password'
    //       value={password}
    //       onChange={({target})=>{setPassword(target.value)}}
    //     />
    //   </div>
    //   <button type='submit'>login</button>
    // </form>
    
    <form onSubmit={(event)=>{
      event.preventDefault()
      handleSubmitt()
    }}>
      <div className="login-wrap">
	      <div className="login-html">
	      	<input id="tab-1" type="radio" name="tab" className="sign-in" defaultChecked /><label htmlFor="tab-1" className="tab"></label>
	      	<input id="tab-2" type="radio" name="tab" className="sign-up" /><label htmlFor="tab-2" className="tab"></label>
	      	<div className="login-form">
	      		<div className="sign-in-htm">
	      			<div className="group">
	      				<label htmlFor="user" className="label">Username</label>
	      				<input 
                  id="user" 
                  type="text" 
                  className="input" 
                  name='Username'
                  value={username}
                  onChange={({target})=>{setUsername(target.value)}}
                />
	      			</div>
	      			<div className="group">
	      				<label htmlFor="pass" className="label">Password</label>
	      				<input 
                  id="pass" 
                  type="password" 
                  className="input" 
                  data-type="password"
                  name='Password'
                  value={password}
                  onChange={({target})=>{setPassword(target.value)}} 
                />
	      			</div>
	      			{/* <div className="group">
	      				<input id="check" type="checkbox" className="check" checked />
	      				<label htmlFor="check"><span className="icon"></span> Keep me Signed in</label>
	      			</div> */}
	      			<div className="hr"></div>
	      			<div className="group">
	      				{/* <input type="submit" className="button" value="Sign In" /> */}
                <button type='submit' className='button'>login</button>
	      			</div>
	      		</div>
	      	</div>
	      </div>
      </div>
    </form>
    
  )
}

export default LoginForm