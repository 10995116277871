const CortinaError = ({closeCortina, bloqueos, setCurrentOperation, setLastScreen, setScreen }) => {
  return(
    <div 
      className="menucontainer active cortina-error" 
      id='menucontainer'
    >
      <div className="mensaje-error-cortina">
        <p>Operaciones que bloquean la creación:</p>
        <br></br>
        
        {
          bloqueos.map(elem => {
            return <p onClick={()=>{
                    console.log(elem._id)
                    setCurrentOperation(elem._id)
                    setLastScreen('operacionesCrear')
                    setScreen('operacionFicha')
                  }}>
                    {elem.name}: {elem.price}€
                  </p>
          })
        }
        {/* <p onClick={()=>closeCortina()}>cerrar X</p> */}
        <br></br>
        <button className="button-52" onClick={()=>closeCortina()}>cerrar</button>
      </div>
    </div>
  )
}

export default CortinaError